// extracted by mini-css-extract-plugin
export var header = "header-module--header--Jintb";
export var image = "header-module--image--Sfehh";
export var artDirected = "header-module--artDirected--x3U5L";
export var meta = "header-module--meta--tz+Bi";
export var categories = "header-module--categories--MTsRv";
export var category = "header-module--category--iX2f6";
export var ad = "header-module--ad--6VObI";
export var title = "header-module--title--E+tTY";
export var authorLink = "header-module--authorLink--vSHU6";
export var author = "header-module--author--Kj6PN";
export var fadein = "header-module--fadein--RjGuT";