import * as styles from './index.module.scss'

import { graphql } from 'gatsby'
import React from "react"
import Layout from '../../components/layout/index'
import PageHeader from '../../components/page/header'
import PostsGrid from '../../components/posts/grid'
import Seo from '../../components/seo'
import { getField } from '../../helper.js'

const Series = ({ data, pageContext: { locale = 'de', pathDe, pathEn } }) => {
  const { series } = data

  const title = getField(series, 'title', locale)
  const teaser = getField(series, 'teaser', locale)
  const metaTitle = getField(series, 'metaTitle', locale)
  const metaDescription = getField(series, 'metaDescription', locale)
  const keywords = getField(series, 'keywords', locale)
  const sharingImage = getField(series.coverImage, 'file', locale)?.src
  const posts = series.posts.edges.map(node => node.node)

  const localizedUrls = {
    de: pathDe,
    en: pathEn
  }

  return (
    <>
      <Seo
        locale={locale}
        localizedUrls={localizedUrls}
        title={metaTitle}
        sharingTitle={title}
        sharingDescription={teaser}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        keywords={keywords}
        image={sharingImage}
      />
      <Layout locale={locale} localizedUrls={localizedUrls}>
        <PageHeader title={title} />
        <div className={styles.posts}>
          <PostsGrid posts={posts} locale={locale} />
        </div>
      </Layout>
    </>
  )
}

export default Series


export const query = graphql`
  fragment SeriesFragment on CMSSeries {
    __typename
    id
    titleDe
    titleEn
    teaserDe
    teaserEn
    metaTitleDe
    metaTitleEn
    metaDescriptionDe
    metaDescriptionEn
    keywordsDe
    keywordsEn
    coverImage {
      fileDe {
        src
      }
      fileEn {
        src
      }
    }
  }

  query SeriesQuery($id: String!) {
    series: cmsSeries(id: {eq: $id}) {
      ...SeriesFragment
      posts {
        edges {
          node {
            ...PostTile
          }
        }
      }
    }
  }
`


//
// export const query = graphql`
//   query SeriesQuery($contentfulId: String!, $locale: String) {
//     series: contentfulSeries(contentful_id: {eq: $contentfulId}, node_locale: {eq: $locale}) {
//       title
//       slug
//       locale: node_locale
//       sections: modules {
//         ...Text
//         ...Map
//       }
//       image: coverImage {
//         ...Image
//       }
//       sharingTitle: title
//       sharingDescription: description {
//         description
//       }
//       metaTitle: title
//       metaDescription: description {
//         description
//       }
//       metaKeywords: keywords
//       posts {
//         ...PostTile
//       }
//     }
//   }
// `
