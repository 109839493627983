import * as styles from './header.module.scss'

import { graphql, Link } from 'gatsby'
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'

import React from 'react'
import { getField } from '../../helper.js'
import { pathTo } from '../../routes'
import { translate } from '../../translations'
import Container from '../container'
import AdBadge from './ad'

export const HeaderImage = ({ visualMobile, visualMedium, visualLarge }) => {
  if (!visualLarge || !visualMedium || !visualMobile) return null

  return (
    <div className={styles.image}>
      <GatsbyImage
        image={withArtDirection(visualLarge?.gatsbyImageData, [
          {
            media: "(min-width: 768px) and (max-width: 1199px)",
            image: visualMedium?.gatsbyImageData,
          },
          {
            media: "(max-width: 767px)",
            image: visualMobile?.gatsbyImageData,
          },
        ])}
        alt=""
        className={styles.artDirected}
      />
    </div>
  )
}

const PostHeader = ({
  title = "Please add a title",
  advertisement = false,
  locale,
  categories = [],
  series,
  visual,
  visualMobile,
  visualMedium,
  visualLarge,
  author,
  aboutUsPage,
}) => {
  return (
    <div className={styles.header}>
      <HeaderImage
        visual={visual}
        visualMobile={visualMobile}
        visualMedium={visualMedium}
        visualLarge={visualLarge}
      />
      <Container>
        <div className={styles.meta}>
          {(categories && categories.length > 0 &&
            <div className={styles.categories}>
              {categories.map(category => (
                <div key={category.slugDe} className={styles.category}>
                  <Link to={pathTo({ ...category, locale })}>{getField(category, "title", locale)}</Link>
                </div>
              ))}
            </div>
          )}
          {(series &&
            <div className={styles.categories}>
              <div className={styles.category}>
                <Link to={pathTo({ ...series, locale })}>{getField(series, "title", locale)}</Link>
              </div>
            </div>
          )}
          {(advertisement &&
            <div className={styles.ad}>
              <AdBadge locale={locale} />
            </div>
          )}
        </div>
        <h1 className={styles.title} itemProp="name">
          <span>{title}</span>
        </h1>
        {(author && aboutUsPage &&
          <div className={styles.authorLink}>
            <Link className={styles.author} to={pathTo({ ...aboutUsPage, locale })} itemProp="author">
              {translate('post.writtenBy', locale)} {author.name}
            </Link>
          </div>
        )}
        {(author &&
          <div className={styles.authorLink}>
            {translate('post.writtenBy', locale)} <span itemProp="author">{author.name}</span>
          </div>
        )}
      </Container>
    </div>
  )
}

export default PostHeader

export const query = graphql`
  fragment RecipeHeaderImages on CMSRecipe {
    visualMobile: coverImage {
      fileDe {
        gatsbyImageData(
          layout: FULL_WIDTH
          height: 335
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 355
        )
      }
      fileEn {
        gatsbyImageData(
          layout: FULL_WIDTH
          height: 335
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 355
        )
      }
    }

    visualMedium: coverImage {
      fileDe {
        gatsbyImageData(
          height: 525
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 930
        )
      }
      fileEn {
        gatsbyImageData(
          height: 525
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 930
        )
      }
    }

    visualLarge: coverImage {
      fileDe {
        gatsbyImageData(
          height: 701
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 1110
        )
      }
      fileEn {
        gatsbyImageData(
          height: 701
          placeholder: DOMINANT_COLOR
          quality: 55
          width: 1110
        )
      }
    }
  }

  fragment PostHeaderImages on CMSPost {
    visual: coverImage {
      titleDe
      titleEn
      fileDe {
        src
      }
      fileEn {
        src
      }
    }

    visualMobile: coverImage {
      fileDe {
        gatsbyImageData(
          layout: FULL_WIDTH
          height: 335
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 355
        )
      }
      fileEn {
        gatsbyImageData(
          layout: FULL_WIDTH
          height: 335
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 355
        )
      }
    }

    visualMedium: coverImage {
      fileDe {
        gatsbyImageData(
          height: 525
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 930
        )
      }
      fileEn {
        gatsbyImageData(
          height: 525
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 930
        )
      }
    }

    visualLarge: coverImage {
      fileDe {
        gatsbyImageData(
          height: 701
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 1110
        )
      }
      fileEn {
        gatsbyImageData(
          height: 701
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 1110
        )
      }
    }
  }

  fragment CookbookPostHeaderImages on CMSCookbookPost {
    visual: coverImage {
      titleDe
      titleEn
      fileDe {
        src
      }
      fileEn {
        src
      }
    }

    visualMobile: coverImage {
      fileDe {
        gatsbyImageData(
          layout: FULL_WIDTH
          height: 335
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 355
        )
      }
      fileEn {
        gatsbyImageData(
          layout: FULL_WIDTH
          height: 335
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 355
        )
      }
    }

    visualMedium: coverImage {
      fileDe {
        gatsbyImageData(
          height: 525
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 930
        )
      }
      fileEn {
        gatsbyImageData(
          height: 525
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 930
        )
      }
    }

    visualLarge: coverImage {
      fileDe {
        gatsbyImageData(
          height: 701
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 1110
        )
      }
      fileEn {
        gatsbyImageData(
          height: 701
          placeholder: DOMINANT_COLOR
          quality: 55
          fit: "thumb"
          width: 1110
        )
      }
    }
  }
`
